var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { marginTop: "8px" } }, [
    _c("p", { staticClass: "has-text-weight-medium" }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _c("p", { staticClass: "has-text-weight-medium has-text-grey" }, [
      _vm._v(_vm._s(_vm.fieldValue))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }