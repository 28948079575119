<template>
  <fragment>

    <b-modal :active="modalIsShowing" :on-cancel="onModalClose">
      <app-modal-template >
        <template slot="title">
          <h1>Información</h1>
          <h4 class="is-size-6 has-text-grey">Detalle de los usuarios firmantes</h4>
        </template>
        <template slot="content">
            <b-icon
              v-if="selfServeRequestGetOrganizationData"
              pack="fas"
              icon="spinner"
              custom-class="fa-spin">
            </b-icon>
            <div v-else :style="{padding:'10px 6px'}" class="tile is-ancestor" >
               <div v-for="user in selfServeGetOrganizationDataSuccess" :style="{marginRight:'10px'}" :key="user.id" class="tile is-5" >
                <div class="is-child box" >
                  <p class="is-size-6 has-text-weight-medium">{{ user.isSigner ? 'Usuario de Firma' : 'Usuario de Registro' }}</p>
                  <app-user-info-item :value="`${user.name} ${user.lastName}`" label="Nombre y apellido" />
                  <app-user-info-item :value="user.email" label="Email" />
                  <app-user-info-item :value="user.role" label="Rol" />
                  <app-user-info-item :value="user.phone" label="Teléfono" />
                </div>
              </div>
            </div>

        </template>
        <template slot="buttons">
          <button class="button is-accent" @click="onModalClose">Volver</button>
        </template>
      </app-modal-template>
    </b-modal>

  <div id="main-container" class="column">
    <div :style="{position:'relative'}" class="card" >
      <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false">
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin">
        </b-icon>
      </b-loading>
      <header class="card-header is-flex is-flex--center is-flex--space-between">
          <div>
            <p :style="{paddingBottom: 0}" class="card-header-title is-size-5 ">Self Serve</p>
            <p :style="{paddingLeft: '0.75rem', paddingBottom: '1rem'}" class="is-size-8 has-text-grey" >La lista debajo muestra todas las organizaciones pendientes de aprobación que hay</p>
          </div>
          <app-input-search @on-text-enter="searchFilteredOrganizations"></app-input-search>
      </header>

      <div class="card-content">
          <b-table
            :loading="selfServeOrganizationsRequest"
            :data="selfServeOrganizations.length ? selfServeOrganizations : []"
            :sort-icon="'chevron-up'"
            :sort-icon-size="'is-small'"
            :default-sort="[sortField, sortOrder]"
            :total="selfServeTotalItems"
            default-sort-direction="desc"
            per-page="20"
            backend-sorting
            backend-pagination
            paginated
            striped
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            @page-change="onPageChange"
            @sort="onSort"
          >
        <template slot-scope="props">
          <b-table-column field="createdAt" sortable label="Fecha" width="40">{{ format_date(props.row.createdAt) }}</b-table-column>
          <b-table-column field="name" label="Organización" sortable centered >{{ props.row.name }}</b-table-column>
          <b-table-column field="productId" label="Producto" sortable centered>{{ mapProductId(props.row.productId) }}</b-table-column>
          <b-table-column field="status" label="Estado"  centered>{{ getStatus(props.row.pending,props.row.signedTC,props.row.rejected) }}</b-table-column>
          <b-table-column field="actions" label="Acciones" centered>
            <b-icon :style="{cursor: 'pointer'}" pack="fas" icon="eye"  @click.native="getSelfServeOrganizationInfo(props.row.id);showModal()"  />
          </b-table-column>
          <b-table-column field="check" label="Check" centered>
              <b-button :disabled="status==='signed'||status==='approved'" type="is-primary" @click="approveSelfServeOrganization(props.row.id);isLoading=true">
                Autorizar</b-button>
              <b-button :disabled="props.row.rejected||status==='signed'||status==='approved'" :style="{marginLeft:'1rem'}" type="is-danger" @click="rejectSelfServeOrganization(props.row.id);isLoading=true">Rechazar</b-button>
          </b-table-column>
        </template>
        <template slot="empty"><section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-show="!selfServeOrganizationsError && !selfServeOrganizationsRequest">
                  <b-icon  pack="fas" icon="exclamation" size="is-large" ></b-icon>
                </p>
                <p
                  v-show="!selfServeOrganizationsError && !selfServeOrganizationsRequest"
                >{{ $t('warnings.accountNoData') }}</p>
              </div>
              <div
                v-show="selfServeOrganizationsError"
                class="content has-text-danger has-text-centered"
              >
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError')}}</p>
              </div>
            </section></template>
      </b-table>
    </div>
  </div>
  </div>
    </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import InputSearch from './InputSearch'
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'
import UserInfoItem from './UserInfoItem.vue'

export default {
  components: {
    'app-input-search': InputSearch,
    'app-modal-template': ModalTemplate,
    'app-user-info-item': UserInfoItem
  },
  data() {
    return {
      organizationInfo: undefined,
      modalIsShowing: false,
      isLoading: false,
      sortField: 'name',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      status: 'pending',
      q: ''
    }
  },
  computed: {
    ...mapGetters([
      'selfServeOrganizations',
      'selfServeTotalItems',
      'tokenValid',
      'selfServeOrganizationsError',
      'selfServeOrganizationsRequest',
      'selfServeApproveOrganizationError',
      'selfServeApproveOrganizationSuccess',
      'selfServeRejectOrganizationError',
      'selfServeRejectOrganizationSuccess',
      'selfServeGetOrganizationDataError',
      'selfServeGetOrganizationDataSuccess',
      'selfServeRequestGetOrganizationData'
    ])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    },
    selfServeApproveOrganizationError(value) {
      if (value) {
        this.showErrorMessage(value)
        this.isLoading = false
      }
    },
    selfServeRejectOrganizationError(value) {
      if (value) {
        this.showErrorMessage(value)
        this.isLoading = false
      }
    },
    selfServeApproveOrganizationSuccess(value) {
      if (value) {
        this.showSuccessMessage('La organizacion ha sido autorizada')
        this.removeLoadingAndRefetch()
      }
    },
    selfServeRejectOrganizationSuccess(value) {
      if (value) {
        this.showSuccessMessage('La organizacion ha sido rechazada')
        this.removeLoadingAndRefetch()
      }
    }
  },
  mounted() {
    this.loadAsyncData()
  },
  methods: {
    ...mapActions(['getSelfServeOrgs', 'approveSelfServeOrganization', 'rejectSelfServeOrganization', 'getSelfServeOrganizationInfo']),
    loadAsyncData() {
      const { organizationInfo, modalIsShowing, isLoading, ...query } = this.$data
      this.getSelfServeOrgs(query)
    },
    searchFilteredOrganizations({ textToSearch, status }) {
      this.q = textToSearch
      this.status = status
      this.loadAsyncData()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.page = 1
      this.loadAsyncData()
    },
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    mapProductId(id) {
      switch (id) {
        case 9:
          return 'IDX'
        default:
          return 'unknown'
      }
    },
    getStatus(isPending, isSigned, isRejected) {
      if (isPending) return 'Pending'
      if (!isSigned) return 'Sin firma'
      if (isRejected) return 'Rechazada'
      return 'Aprobada'
    },
    showErrorMessage(errorMsg) {
      this.$buefy.snackbar.open({ message: `¡Ups algo salio mal! ${errorMsg}`, type: 'is-danger' })
    },
    showSuccessMessage(successMsg) {
      this.$buefy.snackbar.open(`${successMsg}`)
    },
    removeLoadingAndRefetch() {
      this.isLoading = false
      this.loadAsyncData()
    },
    showModal() {
      this.modalIsShowing = true
    },
    onModalClose() {
      this.modalIsShowing = false
    }
  }
}
</script>

<style lang="scss" >
.table td {
  vertical-align: middle;
}
.modal-card {
  margin: auto !important;
}
</style>
