<template>
   <div :style="{marginTop:'8px'}">
        <p class="has-text-weight-medium">{{label}}</p>
        <p class="has-text-weight-medium has-text-grey">{{fieldValue}}</p>
    </div>
</template>

<script>
export default {
  props: {
    label: { required: true, type: String },
    value: { type: String }
  },
  computed: {
    fieldValue() {
      return this.value.trim() || '...'
    }
  }
}
</script>

<style>
</style>
