<template>
  <b-field :style="{paddingRight:'1.5rem'}">
    <b-select
      v-if="hasFilter"
      v-model="status"
      placeholder="Org type"
      @input="triggerRadioChange"
    >
      <option value="pending">Por autorizar</option>
      <option value="signed">Sin Firma</option>
      <option value="rejected">Rechazados</option>
      <option value="approved">Aprobadas</option>
    </b-select>
    <b-input
      ref="searchWidget"
      v-model="queryText"
      placeholder="Presiona ENTER para buscar..."
      icon-pack="fas"
      icon-right="search"
      autofocus
      @keyup.enter.native="onTextEnter"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  name: 'inputSearch',
  props: {
    hasFilter: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      queryText: '',
      status: 'pending'
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.$refs.searchWidget.focus()
    /* if (this.$route.query.hasOwnProperty('q')) {
      this.queryText = this.$route.query.q
      if (this.$route.query.hasOwnProperty('status')) {
        this.status = this.$route.query.status
        this.$router.push({ name: this.$router.name, query: { q: this.queryText, status: this.status } })
        this.$emit('on-text-enter', { textToSearch: this.queryText, status: this.status })
      }
    } */
  },
  methods: {
    onTextEnter() {
      // this.$router.push({ name: this.$router.name, query: { q: this.queryText, status: this.status } })
      this.$emit('on-text-enter', { textToSearch: this.queryText, status: this.status })
    },
    triggerRadioChange() {
      // this.$router.push({ name: this.$router.name, query: { q: this.queryText, status: this.status } })
      this.$emit('on-text-enter', { textToSearch: this.queryText, status: this.status })
    }
  }
}
</script>

<style scoped>
</style>
